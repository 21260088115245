import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useTranslation, Trans } from "react-i18next";
import * as R from "ramda";
import settings from "../../../settings";
import { makeReportAPIUrl } from "./havfunnHelpers";
import { makeObservationSeriesAPIUrl } from "./dugnadHelpers";
import { havfunnIcons, dugnadIcons } from "./icons";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./MapControls.module.scss";

const MapControls = ({
	options,
	havfunn,
	dugnad
}: {
	options: {
		format: string;
		setFormat: (a: string) => void;
		charset: string;
		setCharset: (a: string) => void;
		delimiter: string;
		setDelimiter: (a: string) => void;
		recordDelimiter: string;
		setRecordDelimiter: (a: string) => void;
		after: Date;
		before: Date;
		setBefore: (a: Date) => void;
		setAfter: (a: Date) => void;
		bounds: { n: number; e: number; s: number; w: number };
	};
	havfunn: {
		selectedCategories: string[];
		setSelectedCategories: (a: string[]) => void;
		show: boolean;
		setShow: (a: boolean) => void;
	};
	dugnad: {
		selectedTypes: string[];
		setSelectedTypes: (a: string[]) => void;
		show: boolean;
		setShow: (a: boolean) => void;
	};
}): JSX.Element => {
	const { t } = useTranslation();

	// Keep track of the active tab
	const [activeTab, setActiveTab] = useState<"general" | "havfunn" | "dugnad" | "otherPages">("general");

	// Get available categories in Havfunn
	const [categories, setCategories] = useState<string[]>([]);
	useEffect(() => {
		void fetch(`${settings.HAVFUNN_URL}/api/categories`)
			.then(res => res.json() as Promise<{ name: string }[]>)
			.then(R.pluck("name"))
			.then(setCategories);
	}, []);

	// Get available types in Dugnad
	const [types, setTypes] = useState<string[]>([]);
	useEffect(() => {
		void fetch(`${settings.TIDSSERIER_URL}/api/observationTypes`)
			.then(res => res.json() as Promise<{ name: string }[]>)
			.then(R.pluck("name"))
			.then(setTypes);
	}, []);

	// Build the URL to fetch the reports from the API
	const havfunnApiUrl = makeReportAPIUrl({
		format: options.format,
		charset: options.charset,
		delimiter: options.delimiter,
		recordDelimiter: options.recordDelimiter,
		after: options.after,
		before: options.before,
		top: options.bounds.n,
		bottom: options.bounds.s,
		left: options.bounds.w,
		right: options.bounds.e,
		category: havfunn.selectedCategories,
		validationStatus: "validatedOnly"
	});
	const dugnadApiUrl = makeObservationSeriesAPIUrl({
		after: options.after,
		before: options.before,
		top: options.bounds.n,
		bottom: options.bounds.s,
		left: options.bounds.w,
		right: options.bounds.e,
		format: options.format,
		charset: options.charset,
		delimiter: options.delimiter,
		recordDelimiter: options.recordDelimiter,
		types: dugnad.selectedTypes.join(",")
	});

	return (
		<form className="dataportal-map-controls mt-3">
			{/* General controls */}
			<div className="card">
				<div className="card-header">
					<ul className="nav nav-tabs card-header-tabs">
						<li className="nav-item">
							<button
								type="button"
								className={`btn btn-link nav-link ${
									activeTab === "general" ? "active" : ""
								}`}
								onClick={() => setActiveTab("general")}
							>
								{t("controltabs:general")}
							</button>
						</li>
						<li className="nav-item">
							<button
								type="button"
								className={`btn btn-link nav-link ${
									activeTab === "havfunn" ? "active" : ""
								}`}
								onClick={() => setActiveTab("havfunn")}
							>
								{t("controltabs:havfunn")}
							</button>
						</li>
						<li className="nav-item">
							<button
								type="button"
								className={`btn btn-link nav-link ${
									activeTab === "dugnad" ? "active" : ""
								}`}
								onClick={() => setActiveTab("dugnad")}
							>
								{t("controltabs:dugnad")}
							</button>
						</li>
						<li className="nav-item">
							<button
								type="button"
								className={`btn btn-link nav-link ${
									activeTab === "otherPages" ? "active" : ""
								}`}
								onClick={() => setActiveTab("otherPages")}
							>
								{t("controltabs:otherPages")}
							</button>
						</li>
					</ul>
				</div>
				{/* General controls */}
				<div className={`card-body ${activeTab === "general" ? "" : "d-none"}`}>
					<fieldset className="form-group">
						<label>{t("from")}</label>
						<div>
							{/* Div necessary to make styling correct */}
							<DatePicker
								className="form-control"
								selected={options.after}
								onChange={date =>
									options.setAfter((date as Date) ?? new Date())
								}
								dateFormat={"yyyy-MM-dd"}
							/>
						</div>
					</fieldset>
					<fieldset className="form-group">
						<label>{t("to")}</label>
						<div>
							{/* Div necessary to make styling correct */}
							<DatePicker
								className="form-control"
								selected={options.before}
								onChange={date =>
									options.setBefore((date as Date) ?? new Date())
								}
								dateFormat={"yyyy-MM-dd"}
							/>
						</div>
					</fieldset>
					<fieldset>
						<label>{t("format")}</label>
						<select
							className="form-control"
							value={options.format}
							onChange={e => options.setFormat(e.target.value ?? "json")}
						>
							<option value="csv">{t("formats:csv")}</option>
							<option value="json">{t("formats:json")}</option>
						</select>
						<p className="text-muted">{t("downloadInOtherTabs")}</p>
					</fieldset>
					{options.format === "csv" ? (
						<React.Fragment>
							<fieldset className="form-group">
								<label>{t("charset")}</label>
								<select
									className="form-control"
									value={options.charset}
									onChange={e =>
										options.setCharset(e.target.value)
									}
								>
									<option value="windows-1252">
										Windows-1252
									</option>
									<option value="utf-8">UTF-8</option>
								</select>
							</fieldset>
							<fieldset className="form-group">
								<label>{t("delimiter")}</label>
								<input
									className="form-control"
									value={options.delimiter}
									onChange={e =>
										options.setDelimiter(e.target.value)
									}
								/>
							</fieldset>
							<fieldset className="form-group">
								<label>{t("recordDelimiter")}</label>
								<select
									className="form-control"
									value={options.recordDelimiter}
									onChange={e =>
										options.setRecordDelimiter(
											e.target.value
										)
									}
								>
									<option value={"\r\n"}>Windows (CRLF)</option>
									<option value={"\n"}>UNIX (LF)</option>
								</select>
							</fieldset>
						</React.Fragment>
					) : null}
				</div>
				{/* Havfunn controls */}
				<div className={`card-body ${activeTab === "havfunn" ? "" : "d-none"}`}>
					<fieldset className="form-group">
						<label>
							<input
								type="checkbox"
								checked={havfunn.show}
								onChange={e => havfunn.setShow(e.target.checked)}
							/>
							<span className="ml-1">{t("havfunn:showHavfunnResults")}</span>
						</label>
					</fieldset>
					<fieldset className="form-group">
						<label>{t("havfunn:category_plural")}</label>
						<div>
							{/* Div necessary to make styling correct */}
							<Select
								placeholder={t("select") + "..."}
								isMulti={true}
								isClearable={true}
								options={R.map(
									name => ({
										label: t<string>(
											`havfunnCategories:${name}`
										),
										value: name
									}),
									categories
								)}
								value={R.map(
									name => ({
										label: t(`havfunnCategories:${name}`),
										value: name
									}),
									havfunn.selectedCategories
								)}
								onChange={
									/* eslint-disable */
									e =>
										R.compose(
											havfunn.setSelectedCategories,
											R.map(R.prop("value"))
										)(e ?? [])
									/* eslint-enable */
								}
							/>
						</div>
					</fieldset>
					<fieldset className="form-group">
						<a
							className="btn btn-primary"
							href={havfunnApiUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("downloadData")}
						</a>
					</fieldset>
					<div className="form-group">
						<p>
							{t("havfunn:apiDocDescription")}:{" "}
							<a href="/docs/Havfunn API.pdf">{t("documentation")}</a>
						</p>
					</div>
					<div className="row">
						{R.map<string, JSX.Element>(c => (
							<div key={c} className="col-sm-12 col-xl-6">
								<img src={havfunnIcons[c].options.iconUrl} alt="" />
								<span className="ml-1">
									{t(`havfunnCategories:${c}`)}
								</span>
							</div>
						))(categories)}
					</div>
				</div>
				{/* Dugnad controls */}
				<div className={`card-body ${activeTab === "dugnad" ? "" : "d-none"}`}>
					<fieldset className="form-group">
						<label>
							<input
								type="checkbox"
								checked={dugnad.show}
								onChange={e => dugnad.setShow(e.target.checked)}
							/>
							<span className="ml-1">{t("dugnad:showDugnadResults")}</span>
						</label>
					</fieldset>{" "}
					<fieldset className="form-group">
						<label>{t("dugnad:observationType_plural")}</label>
						<div>
							{/* Div necessary to make styling correct */}
							<Select
								placeholder={t("select") + "..."}
								isMulti={true}
								isClearable={true}
								options={R.map(
									name => ({
										label: t<string>(
											`dugnadObservationTypes:${name}`
										),
										value: name
									}),
									types
								)}
								value={R.map(
									name => ({
										label: t(
											`dugnadObservationTypes:${name}`
										),
										value: name
									}),
									dugnad.selectedTypes
								)}
								onChange={
									/* eslint-disable */
									e =>
										R.compose(
											dugnad.setSelectedTypes,
											R.map(R.prop("value"))
										)(e ?? [])
									/* eslint-enable */
								}
							/>
						</div>
					</fieldset>
					<fieldset className="form-group">
						<a
							className="btn btn-primary"
							href={dugnadApiUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("downloadData")}
						</a>
					</fieldset>
					<div className="form-group">
						<p>
							{t("dugnad:apiDocDescription")}:{" "}
							<a href="/docs/Tidsserier API.pdf">{t("documentation")}</a>
						</p>
					</div>
					<div className="row">
						{R.map<string, JSX.Element>(c => (
							<div key={c} className="col-sm-12 col-xl-6">
								<img src={dugnadIcons[c].options.iconUrl} alt="" />
								<span className="ml-1">
									{t(`dugnadObservationTypes:${c}`)}
								</span>
							</div>
						))(types)}
					</div>
				</div>
				{/* Other pages */}
				<div className={`card-body ${activeTab === "otherPages" ? "" : "d-none"}`}>
					<p>{t("otherPagesDescription")}</p>
					<dl>
						<dt>Artsdatabanken</dt>
						<dd>
							<a
								href="https://www.artsdatabanken.no/"
								target="_blank"
								rel="noreferrer noopener"
							>
								https://www.artsdatabanken.no/
							</a>
						</dd>
						<dt>Ryddeaksjoner, Hold Norge rent</dt>
						<dd>
							<a
								href="https://holdnorgerent.no/"
								target="_blank"
								rel="noreferrer noopener"
							>
								https://holdnorgerent.no/
							</a>
						</dd>
						<dt>Tapt og funnet redskap</dt>
						<dd>
							<a
								href="https://www.fiskeridir.no/Fritidsfiske/Meld-tapt-og-funnen-reiskap"
								target="_blank"
								rel="noreferrer noopener"
							>
								https://www.fiskeridir.no/Fritidsfiske/Meld-tapt-og-funnen-reiskap
							</a>
						</dd>
						<dt>Miljølære</dt>
						<dd>
							<a
								href="https://www.miljolare.no/"
								target="_blank"
								rel="noreferrer noopener"
							>
								https://www.miljolare.no/
							</a>
						</dd>
					</dl>
				</div>
			</div>
			<div className="card mt-3">
				<div className={`${styles["new-map-link-card"]} card-body `}>
					<p>
						<Trans i18nKey="common:mapLinkToNew">
							<a
								href="https://nye.dugnadforhavet.no/observasjoner"
								target="_blank"
								rel="noreferrer noopener"
							>
								her
							</a>
						</Trans>
					</p>
				</div>
			</div>
		</form>
	);
};

export default MapControls;
export { MapControls };

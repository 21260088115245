import { Settings, settingsSchema } from "./models/Settings";

declare global {
	interface Window {
		__env?: Record<string, string>;
	}
}

// Validate the environment variables
// XXX Values from the environment are always strings. Need to convert them to proper types before giving them
// to the schema
// XXX Apps created with create-react-app ignore (almost)
// all environment variables not starting with "REACT_APP_". See
// https://create-react-app.dev/docs/adding-custom-environment-variables/

const settings: Settings = settingsSchema.validateSync({
	NODE_ENV: process.env.NODE_ENV,
	HAVFUNN_URL: window.__env?.HAVFUNN_URL ?? process.env.REACT_APP_HAVFUNN_URL,
	TIDSSERIER_URL: window.__env?.TIDSSERIER_URL ?? process.env.REACT_APP_TIDSSERIER_URL,
	EXCURSION_URL: window.__env?.EXCURSION_URL ?? process.env.REACT_APP_EXCURSION_URL,
	ACCOUNTS_URL: window.__env?.ACCOUNTS_URL ?? process.env.REACT_APP_ACCOUNTS_URL,
	ADMIN_URL: window.__env?.ADMIN_URL ?? process.env.REACT_APP_ADMIN_URL,
	GA_TOKEN: window.__env?.GA_TOKEN ?? process.env.REACT_APP_GA_TOKEN
});

export default settings;
export { settings };

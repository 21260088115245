import React, { useEffect, useState } from "react";
import { Dataportal as ActualDataportal } from "../common/Dataportal";
import * as R from "ramda";

const Dataportal = ({ navbar }: { navbar: React.RefObject<HTMLDivElement> }): JSX.Element => {
	// Make the dataportal occupy the entire viewport
	const [dataportalHeight, setDataportalHeight] = useState(500);
	useEffect(() => {
		const resizeDataportal = () => {
			if (R.isNil(navbar.current)) {
				return;
			}

			const viewportHeight = window.innerHeight;
			const navbarHeight = navbar.current.clientHeight;
			setDataportalHeight(viewportHeight - navbarHeight);
		};

		resizeDataportal();

		window.addEventListener("resize", resizeDataportal);
		return () => window.removeEventListener("resize", resizeDataportal);
	}, [navbar]);

	return (
		<div className="container-fluid">
			<ActualDataportal height={dataportalHeight} />
		</div>
	);
};

export default Dataportal;
export { Dataportal };

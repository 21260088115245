import React, { useState, useEffect } from "react";
import settings from "../../settings";
import * as R from "ramda";
import formatDate from "../../helpers/formatDate";
import { useTranslation } from "react-i18next";
import "./Statistics.scss";

const Statistics = (): JSX.Element => {
	const { t } = useTranslation();

	// Count total registrations
	const [registrationsCount, setRegistrationsCount] = useState<number | null>(null);
	useEffect(() => {
		void Promise.all([
			fetch(`${settings.HAVFUNN_URL}/api/reports/countReports`).then(
				res => res.json() as Promise<number>
			),
			fetch(`${settings.TIDSSERIER_URL}/api/observations/count`).then(
				res => res.json() as Promise<number>
			)
		]).then(([havfunnCount, tidsserierCount]) => setRegistrationsCount(havfunnCount + tidsserierCount));
	}, []);

	// Get user count
	const [userCount, setUserCount] = useState<number | null>(null);
	useEffect(() => {
		void fetch(`${settings.ACCOUNTS_URL}/users/countUsers`)
			.then(res => res.json())
			.then(setUserCount);
	}, []);

	// Get last registration
	const [lastRegistrationTs, setLastRegistrationTs] = useState<Date | null>(null);
	useEffect(() => {
		void Promise.all([
			fetch(`${settings.HAVFUNN_URL}/api/reports/latestRegistrationTs`)
				.then(res => res.json() as Promise<string>)
				.then(tsString => new Date(tsString)),
			fetch(`${settings.TIDSSERIER_URL}/api/observations/latestObservationTs`)
				.then(res => res.json() as Promise<string>)
				.then(tsString => new Date(tsString))
		])
			.then(([havfunnTs, tidsserierTs]) => R.max(havfunnTs, tidsserierTs))
			.then(setLastRegistrationTs);
	}, []);

	// Get last registration categories
	const [latestRegistrationCategories, setLatestRegistrationCategories] = useState<
		{ category: string; from: string }[]
	>([]);
	useEffect(() => {
		void Promise.all([
			fetch(`${settings.HAVFUNN_URL}/api/reports/latestRegistrationCategories`)
				.then(res => res.json() as Promise<{ category: string; date: string }[]>)
				.then(res => res.map(r => ({ ...r, from: "havfunn" }))),
			fetch(`${settings.TIDSSERIER_URL}/api/observations/latestObservationsCategories`)
				.then(res => res.json() as Promise<{ category: string; date: string }[]>)
				.then(res => res.map(r => ({ ...r, from: "tidsserier" })))
		])
			.then(R.flatten)
			.then(R.sortBy(R.prop("date")))
			.then(r => R.reverse(r))
			.then(r => r.slice(0, 3))
			.then(setLatestRegistrationCategories);
	}, []);

	return (
		<div className="statistics">
			<h2>{t("statistics:statistics")}</h2>
			<ul className="list-unstyled">
				<li className="mb-3">
					<span>
						{R.isNil(registrationsCount)
							? t("common:loading") + "…"
							: registrationsCount}
					</span>
					<span>{t("statistics:noOfRegistrations")}</span>
				</li>
				<li className="mb-3">
					<span>{R.isNil(userCount) ? t("common:loading") + "…" : userCount}</span>
					<span>{t("statistics:noOfUsers")}</span>
				</li>
				<li className="mb-3">
					<span>
						{R.isNil(lastRegistrationTs)
							? t("common:loading") + "…"
							: formatDate(lastRegistrationTs)}
					</span>
					<span>{t("statistics:latestRegistration")}</span>
				</li>
				<li>
					<ul className="list-inline">
						{/* eslint-disable prettier/prettier */}
						{latestRegistrationCategories.length === 0
							? t("common:loading") + "…"
							: latestRegistrationCategories
								.map(({ category, from }) =>
									t(
										(from === "havfunn"
											? "havfunnCategories"
											: "dugnadObservationTypes") +
												":" +
												category
									)
								)
								.join(", ")}
						{/* eslint-enable prettier/prettier */}
					</ul>
					<span>{t("statistics:threeLatestRegisteredSpecies")}</span>
				</li>
			</ul>
		</div>
	);
};

export default Statistics;
export { Statistics };
